/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Global cross-site imports

// import "@popperjs/core/dist/umd/popper.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

import * as bootstrap from "bootstrap"
import ReactDOM from "react-dom/client"

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
window.bootstrap = bootstrap
