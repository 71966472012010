module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"archetipi-digitali","short_name":"archetipi-digitali","start_url":"/","background_color":"#FFFFFF","theme_color":"#005FB6","display":"standalone","icon":"/Applications/MAMP/htdocs/gatsby/archetipi-digitali/packages/gatsby-wp-archetipi-digitali/src/images/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cb3da8f003314f756fea2156c56f925c"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://phpstack-319227-4019126.cloudwaysapps.com/graphql","schema":{"timeout":360000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"html":{"createStaticFiles":false,"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"createFileNodes":false,"excludeFieldNames":["contentNodes","seo","ancestors","contentType","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","sizes","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"InvestorDocCategory":{"exclude":true},"InvestorDocYear":{"exclude":true},"InvestorDoc":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../../packages/gatsby-wp-archetipi-digitali/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://phpstack-319227-4019126.cloudwaysapps.com","frontendUrl":"https://www.archetipi-digitali.it","siteLanguage":"it_IT"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
